import $ from 'jquery';
import 'slick-carousel';


$('.burger').on("click", function() {
  $(this).toggleClass("burger_open");
  $('.header').toggleClass('nav-active');
})

$(".basket__icon").on("click", function (e) {
  e.preventDefault();
  $(".basket__dropdown").slideToggle();
})

$(".solutions__slider").slick({
  prevArrow: $(".solutions__nav-prev"),
  nextArrow: $(".solutions__nav-next"),
  variableWidth: true,
  swipeToSlide: false,
  swipe: false,
  autoplay: true
})

$('.portfolio__slider').slick({
  slidesToShow: 3,
  prevArrow: $(".portfolio__nav-prev"),
  nextArrow: $(".portfolio__nav-next"),
  autoplay: true,
  responsive: [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    }
  ]
})

$('.similar__slider').slick({
  slidesToShow: 3,
  prevArrow: $(".similar__nav-prev"),
  nextArrow: $(".similar__nav-next"),
  autoplay: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        variableWidth: true,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        variableWidth: true,
        arrows: false,
        dots: true
      }
    },
    
  ]
})

$('.pdp-docs__slider').slick({
  slidesToShow: 3,
  prevArrow: $(".pdp-docs__nav-prev"),
  nextArrow: $(".pdp-docs__nav-next"),
  autoplay: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        variableWidth: true,
        slidesToShow: 1
      }
    }
  ]
})


$(document).on("click", ".count__btn", function (e) {
  e.preventDefault();
  const inputEL = $(this).siblings("input");
  const currentValue = parseInt(inputEL.val());
  if ($(this).hasClass("count__minus") && currentValue >= 2) {
    inputEL.val(currentValue - 1);
  } else if ($(this).hasClass("count__plus")) {
    inputEL.val(currentValue + 1);
  }
});

let introSlider = $(".intro-slider");
introSlider.on('init', function (event, slick) {
  let totalSlides;
  if (slick.slideCount.toString().length == 1) {
    totalSlides = "0" + slick.slideCount.toString();
  }
  $('.intro-slider__indicator-total').text(totalSlides);
});

introSlider.slick({
  prevArrow: $(".intro-slider__nav-prev"),
  nextArrow: $(".intro-slider__nav-next"),
  fade: true,
  // dots: true,
  // customPaging: function (slick, index) {
  //   console.log(slick);
  //   return '<a>' + (index + 1) + '</a>';
  // }
}) 

introSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
  let current = nextSlide + 1;
  if (current.toString().length == 1) {
    current = "0" + current.toString();
  }
  $(".intro-slider__indicator-current").text(current);
});


$(document).on('click', '.dropdown', function (e) {
  e.preventDefault();
  $(this).toggleClass('active').find('.dropdown__list').slideToggle();
});

$(document).on('click', function (e) {
  console.log(e.target);
  if (!$(e.target).closest('.dropdown').length) {
    $('.dropdown.active').removeClass('active').find('.dropdown__list').slideUp();
  }
  if (!$(e.target).closest('.basket').length) {
    $('.basket__dropdown').slideUp()
  }
})

$(document).on("click", ".dropdown__item", function () {
  let val = $(this).text() || $(this).data('value');

  if ($(this).closest('.dropdown').find('input').length) {
    $(this).closest('.dropdown').find('input').val(val)
  } else {
    $(this).closest('.dropdown').find('.input').text(val);
  }
  $(this).addClass('active').siblings('.active').removeClass('active');
})


$(".filters-reset").on("click", function (e) {
  e.preventDefault();
  $(".filters .dropdown__list").each((i, el) => {
    $(el).find(".dropdown__item").first().click();
  })
})

$(".models__item").on("click", function () {
  $(this).addClass("active").siblings('.active').removeClass("active");
})


$(".config__param-option").on("click", function () {
  $(this).addClass("active").siblings(".active").removeClass('active');
})


// Responsive

$(window).on("resize", resizeChangeBlocks);

function resizeChangeBlocks() {
  if ($(window).outerWidth() < 993) {
    $('.solutions__row').append($('.solutions__nav'));
  } else {
    $('.solutions__info').append($('.solutions__nav'));
  }
}
resizeChangeBlocks();